import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import BaseHeroContainer from "../containers/chirurghi/base-hero"
import ChirurghiSeach from "../components/ChirurghiSearch/ChirurghiSearch"
import capitalize from "../functions/capitalize"
import { useFetch } from "../hooks/useFetch"
import { GET_MODULO } from "../constants/api"
import LoadingView from "../components/LoadingView/LoadingView"

const ChirurghiPage = ({ location,  pageContext: { tratt, city } }) => {
  const path = React.useMemo(() => {
    let path = location.pathname;

    const endsWithSlash= path.endsWith("/");
    const startsWithSlash = path.startsWith("/");

    if(endsWithSlash){
      path = path.substr(0, path.length-1)
    }
    if(startsWithSlash){
      path = path.substr(1)
    }

    return path
  }, [location.pathname]);

  const {data, loading} = useFetch(GET_MODULO(path))
  const currentData = React.useMemo(() =>{
    return data?.find(item => item.key_page === path)
  },[data])

  const keycound =!!city?.comune
  const bannerTitle = currentData ? currentData?.banner_description: `Trova il miglior specialista ${tratt?.id ? "in " + capitalize(tratt?.nome) : ""} ${city?.comune && "a "+ city?.comune || ""}`
  const pageTitle = `Gli specialisti ${tratt?.id ? "in " + tratt?.nome : ""} ${city?.comune ? "a " + city?.comune : ""}`

  const {finan, video, checked} = location?.state || {};


  if(loading){
    return <LoadingView />
  }
  return (
    <Layout>
      <SEO title={pageTitle} description={currentData?.meta_description}/>
      <BaseHeroContainer
        img={require("../images/hero.png")}
        bannerText={bannerTitle}
        text={currentData?.body_description}
        keycound ={keycound}
      />

      <ChirurghiSeach passedFinan={finan} passedChecked={checked} passedVideo={video} tratt={tratt} comune={city} text={currentData?.body_description} />
    </Layout>
  )
}

export default ChirurghiPage
