import React from "react"
import styled from "styled-components"

export const Icon = styled.img`
  margin-right: 10px;
  height: 16px;
  margin-top: -15px;
  width: auto;
  background-size: contain;
`
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  align-items: center;
  align-content: center;
`

export const Text = styled.p`
  font-size: 12px;
`

export default function IconWithText({ img, number, ...restProps }) {
  return (
    <Container {...restProps}>
      <Icon src={img} />
      {number && <Text>{"(" + number + ")"}</Text>}
    </Container>
  )
}
