import React, { useEffect, useState } from "react"

import { Container, Wrapper } from "./styles/ChirurghiSearch.styled"
import ExpandedCardsContainer from "../ExpandedCardsContainer/ExpandedCardsContainer"
import ChirurghiFiltersContainer from "../ChirurghiFilters/ChirurghiFiltersContainer"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchChirurghiFilterAsync,
  fetchMoreChirurghiAsync,
  selectChirurghiFilter,
} from "../../redux/filterSlice"
import StickyBox from "react-sticky-box/dist/esnext"
import {
  fetchCitiesAsync,
  fetchTrattamentiAsync,
} from "../../redux/searchSlice"
import navigateToChirurghi from "../../functions/navigateToChirurghi"
import getClosestCity from "../../functions/getClosestCity"

export default function ChirurghiSeach({ comune, tratt, passedChecked, passedVideo, passedFinan, text }) {
  const chirurghi = useSelector(selectChirurghiFilter)
  const [trattamento, setTrattamento] = useState(tratt)
  const [city, setCity] = useState(comune)
  const [video, setVideo] = React.useState(passedVideo)
  const [finan, setFinan] = React.useState(passedFinan)

  const [checked, setChecked] = useState(passedChecked)
  const [openFilters, setOpenFilters] = useState(false)

  const [filterActive, setFilterActive] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setTrattamento(tratt)
    setCity(comune)
  //  handleSubmit()
  }, [comune,tratt])

  useEffect(() => {
    dispatch(fetchCitiesAsync())
    dispatch(fetchTrattamentiAsync())
    resetFiltri(comune, tratt, video, finan, checked)
  }, [])

  const resetFiltri = (passedComune = null, passedTrattamento = null, passedVideo= null, passedFinan = null, passedCheck = null) => {
    dispatch(
      fetchChirurghiFilterAsync({
        id_comune: passedComune ? passedComune.id_sys : null,
        sesso: passedCheck,
        id_trattamento: passedTrattamento ? passedTrattamento.id : null,
        video_consulto: passedVideo,
        finanziamento: passedFinan,
      })
    )
  }

  const handleClickDesktop = () => {
    //delete filters
    console.log("HEERE")
    setVideo(null)
    setFinan(null)
    setCity(null)
    setTrattamento(null)
    setChecked(null)
    resetFiltri()
  }

  const handleClick = () => {
    if (openFilters) {
      //delete filters
      setVideo(null)
      setFinan(null)
      setCity(null)

      setTrattamento(null)
      setChecked(null)
      setOpenFilters(false)
      setFilterActive(false)
      resetFiltri()
      return
    }
    setOpenFilters(true)
  }

  const handleSubmit = () => {
 dispatch(
      fetchChirurghiFilterAsync({
        id_comune: city ? city.id_sys : null,
        sesso:
          checked == null || checked == 2 ? null : checked == 0 ? "M" : "F",
        id_trattamento: trattamento ? trattamento.id_sys : null,
        video_consulto: video == null ? null : video ? 1 : 0,
        finanziamento: finan == null ? null : finan ? 1 : 1,
      })
    )
    navigateToChirurghi(city?.comune?.toLowerCase() || getClosestCity(), trattamento?.trattamento?.toLowerCase().replace(/ /g, "-"),finan, video, checked)
    setFilterActive(true)
    setOpenFilters(false)
  }

  const fetchMore = table_page => {
    dispatch(
      fetchMoreChirurghiAsync({
        id_comune: city ? city.id_sys : null,
        sesso:
          checked == null || checked == 2 ? null : checked == 0 ? "M" : "F",
        id_trattamento: trattamento ? trattamento.id_sys : null,
        video_consulto: video == null ? null : video ? 1 : 0,
        finanziamento: finan == null ? null : finan ? 1 : 0,
        table_page,
      })
    )
  }

  return (
    <Wrapper>
      <Container>
        <Row gx-0 className="no-gutter justify-content-center">

          <Col sm={12} md={5} lg={4} xl={3}>
            <StickyBox offsetTop={100} offsetBottom={20}>
              <ChirurghiFiltersContainer
                handleClick={handleClick}
                handleClickDesktop={handleClickDesktop}
                handleSubmit={handleSubmit}
                openFilters={openFilters}
                finan={finan}
                setFinan={setFinan}
                checked={checked}
                setChecked={setChecked}
                city={city}
                setCity={setCity}
                trattamento={trattamento}
                setTrattamento={setTrattamento}
              />
            </StickyBox>
          </Col>
          <Col sm={12} md={7} lg={8} xl={9}>
            <ExpandedCardsContainer
              fetchMore={fetchMore}
              chirurghi={chirurghi}
              text={text}
            />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}
