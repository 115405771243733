import React from "react"
import HeroSection from "../../components/HeroSection/HeroSection"
import { Markup } from "interweave"

export default function BaseHeroContainer({ bannerText, img, text, keycound }) {
  return (
    <HeroSection.Trattamenti style={{ height: 350 }} img={img}>
      {keycound ? <Markup content={bannerText}/> :
      <HeroSection.FeatureTrattamenti style={{ marginTop: 0 }}>
        <Markup content={bannerText}/>
      </HeroSection.FeatureTrattamenti>
      }
    </HeroSection.Trattamenti>
  )
}
